import React from 'react';
import Report from '../../components/RightSidebar/Report';
import Information from '../../components/patient/Information';
import { Container } from '@mui/material';
import PatientList from '../../components/patient/PatientList';

const ReportContainer = () => {
    return (
        <>
            <Information />

            <Container maxWidth="lg">
                <Report />
            </Container>
        </>

    )
}

export default PatientList(ReportContainer);