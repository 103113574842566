


import React, { useState, useEffect } from 'react';
import { FormBox } from './style';
import { Grid, Typography, Tooltip } from '@mui/material';
import { Button } from 'src/components/shared';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';
import toast from 'src/utils/toast';
import { useNavigate } from 'react-router-dom';
import { postFollowUpRequest } from 'src/modules/app/store/appActions';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const StyledInputRoot = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column'

});
const StyledInputElement = styled('input')({
  border: '1px solid #ccc',
  borderRadius: '4px',
  padding: '8px',
  fontSize: '16px',
  flex: 1,
});
const CustomNumberInput = React.forwardRef(function CustomNumberInput({ field, form, ...props }, ref) {
  const { value, onChange, onBlur } = field;
  const { errors, touched } = form;
  const isError = Boolean(touched[field.name] && errors[field.name]);

  return (
    <StyledInputRoot sx={{ width: '100%', textAlign: 'center' }}>
      <StyledInputElement
        type="number"
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        {...field}
        {...props}
        style={{
          borderColor: isError ? 'red' : '#ccc',
        }}
      />
      {isError && (
        <span style={{ color: 'red', fontSize: '12px' }}>
          {errors[field.name]}
        </span>
      )}
    </StyledInputRoot>
  );
});


function Followup() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { patient, tokenAppointment } = location.state || {};
  const clinic = useSelector((state) => state.app.clinics);
  const navigate = useNavigate();
  // const [followups, setFollowups] = useState(0);
  // const [remaining, setRemaining] = useState(0);
  // const [days, setDays] = useState(0);


  const validationSchema = Yup.object({
    followups: Yup.number()
      .required('Follow-ups is required')
    ,
    remaining: Yup.number()
      .required('Remaining follow-ups is required')
    ,
    days: Yup.number()
      .required('Days is required')
    ,
  });
  useEffect(() => {
    if (!patient) {
      toast.error('No patient selected. Please select a patient.');
    }
  }, [patient]);
  const handleFollowUpSave = async (values, { resetForm }) => {
    try {
      const token = storage.get('TOKEN');
      const decodedToken = jwtDecode(token);
      const user = decodedToken?.user;
      if (!user) {
        throw new Error('User information is missing. Please log in again.');
      }

      if (!clinic || !clinic.clinicId) {
        throw new Error('Clinic information is missing.');
      }

      const followUpData = {
        clinic: clinic.clinicId,
        user,
        patient: patient.id,
        totalFollowup: values.followUps,
        remainingFollowup: values.remaining,
        days: values.days,
        tokenAppointment: patient.tokenappointment,
      };


      const promise = await new Promise((resolve, reject) => {
        dispatch(postFollowUpRequest({ data: followUpData, resolve, reject }))
      })
      const response = await promise;
      console.log('Before toast:', response);
      toast.success(response.message || 'FollowUp created successfully');
      console.log('FollowUp created successfully')
      resetForm();
    } catch (error) {
      console.error('Error in handleSaveFollowUp:', error);
      toast.error('Failed to add FollowUp: ' + (error.message || error));
    }
  };

  return (
    <>
      <Formik
        initialValues={{ followups: '', remaining: '', days: '' }}
        validationSchema={validationSchema}
        onSubmit={handleFollowUpSave}
      >
        {({ values, setFieldValue, handleChange, handleBlur, errors, touched }) => (
          <Form>
            <FormBox sx={{ marginTop: '20px' }}>
              <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Typography
                  variant="h4"
                  color="#393A96"
                  fontWeight="bold"
                  sx={{ marginLeft: '2%' }}
                >
                  Follow-Up
                </Typography>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold" sx={{ pb: 1 }}>
                    How many follow-ups?
                  </Typography>
                  <Tooltip title="Enter the number of follow-ups" arrow>
                    <Field
                      name="followups"
                      value={values.followups}
                      // onChange={handleChange}
                      onChange={(e) => {
                        // Set the remaining field value to followups when followups is updated
                        const followupsValue = e.target.value;
                        setFieldValue("followups", followupsValue);
                        if (!values.remaining) {
                          setFieldValue("remaining", followupsValue); // Automatically set remaining to followups value
                        }
                      }}
                      onBlur={handleBlur}
                      component={CustomNumberInput}
                      error={Boolean(touched.followups && errors.followups)}
                      helperText={touched.followups && errors.followups}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold" sx={{ pb: 1 }}>
                    Remaining
                  </Typography>
                  <Tooltip title="Enter the number of remaining follow-ups" arrow>
                    <Field
                      name="remaining"
                      value={values.remaining}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      component={CustomNumberInput}
                      error={Boolean(touched.remaining && errors.remaining)}
                      helperText={touched.remaining && errors.remaining}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold" sx={{ pb: 1 }}>
                    Days
                  </Typography>
                  <Tooltip title="Enter the number of days" arrow>
                    <Field
                      name="days"
                      value={values.days}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      component={CustomNumberInput}
                      error={Boolean(touched.days && errors.days)}
                      helperText={touched.days && errors.days}
                    />
                  </Tooltip>
                </Grid>
                <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                  <Tooltip title="Save the follow-up details" arrow>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ width: '200px', textAlign: 'center' }}
                    >
                      Save Followup
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </FormBox>
          </Form>
        )}
      </Formik>
    </>
  );
}
export default Followup;
