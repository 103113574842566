// For Patient
export const ADD_PATIENT_REQUEST = 'ADD_PATIENT_REQUEST';
export const ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS';
export const ADD_PATIENT_FAILURE = 'ADD_PATIENT_FAILURE';
export const SET_APP_LOADING = 'SET_APP_LOADING';

// For getPatient
export const FETCH_PATIENTS_REQUEST = 'FETCH_PATIENTS_REQUEST';
export const FETCH_PATIENTS_SUCCESS = 'FETCH_PATIENTS_SUCCESS';
export const FETCH_PATIENTS_FAILURE = 'FETCH_PATIENTS_FAILURE';

// For Task
export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAILURE = 'ADD_TASK_FAILURE';

// For Clinic
export const SET_CLINICS = 'SET_CLINICS';
export const SET_CURRENT_CLINIC = 'SET_CURRENT_CLINIC';
export const GET_CLINICS = 'GET_CLINICS';

// set Patient
export const SET_CURRENT_PATIENT = 'SET_CURRENT_PATIENTS';


// For Staff
export const ADD_STAFF_REQUEST = 'ADD_STAFF_REQUEST';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';
export const ADD_STAFF_FAILURE = 'ADD_STAFF_FAILURE';
//get staff
export const FETCH_STAFFS_REQUEST = 'FETCH_STAFFS_REQUEST';
export const FETCH_STAFFS_SUCCESS = 'FETCH_STAFFS_SUCCESS';
export const FETCH_STAFFS_FAILURE = 'FETCH_STAFFS_FAILURE';

//get Appointment
export const FETCH_TOKENAPPOINTMENTS_REQUEST = 'FETCH_TOKENAPPOINTMENTS_REQUEST';
export const FETCH_TOKENAPPOINTMENTS_SUCCESS = 'FETCH_TOKENAPPOINTMENTS_SUCCESS';
export const FETCH_TOKENAPPOINTMENTS_FAILURE = 'FETCH_TOKENAPPOINTMENTS_FAILURE';

//getpatientname from Token Appointment

export const FETCH_PATIENT_NAMES_REQUEST = 'FETCH_PATIENT_NAMES_REQUEST';
export const FETCH_PATIENT_NAMES_SUCCESS = 'FETCH_PATIENT_NAMES_SUCCESS';
export const FETCH_PATIENT_NAMES_FAILURE = 'FETCH_PATIENT_NAMES_FAILURE';

//get Tasks


export const FETCH_TASKS_REQUEST = 'FETCH_TASKS_REQUEST';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';

//delete Patient
export const DELETE_PATIENT_REQUEST = 'DELETE_PATIENT_REQUEST';
export const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS';
export const DELETE_PATIENT_FAILURE = 'DELETE_PATIENT_FAILURE';

//delet Staff
export const DELETE_STAFF_REQUEST = 'DELETE_STAFF_REQUEST';
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_FAILURE = 'DELETE_STAFF_FAILURE';

//delete TokenAppointment
export const DELETE_TOKENAPPOINTMENT_REQUEST = 'DELETE_TOKENAPPOINTMENT_REQUEST';
export const DELETE_TOKENAPPOINTMENT_SUCCESS = 'DELETE_TOKENAPPOINTMENT_SUCCESS';
export const DELETE_TOKENAPPOINTMENT_FAILURE = 'DELETE_TOKENAPPOINTMENT_FAILURE';

// Add a new action type for fetching patient by contact number
export const FETCH_PATIENT_BY_CONTACTNO_REQUEST = 'FETCH_PATIENT_BY_CONTACTNO_REQUEST';
export const FETCH_PATIENT_BY_CONTACTNO_SUCCESS = 'FETCH_PATIENT_BY_CONTACTNO_SUCCESS';
export const FETCH_PATIENT_BY_CONTACTNO_FAILURE = 'FETCH_PATIENT_BY_CONTACTNO_FAILURE';

//post Notes
// For Medical Record
export const POST_MEDICAL_NOTES_REQUEST = 'POST_MEDICAL_NOTES_REQUEST';
export const POST_MEDICAL_NOTES_SUCCESS = 'POST_MEDICAL_NOTES_SUCCESS';
export const POST_MEDICAL_NOTES_FAILURE = 'POST_MEDICAL_NOTES_FAILURE';
//post Prescription
export const POST_PRESCRIPTION_REQUEST = 'POST_PRESCRIPTION_REQUEST';
export const POST_PRESCRIPTION_SUCCESS = 'POST_PRESCRIPTION_SUCCESS';
export const POST_PRESCRIPTION_FAILURE = 'POST_PRESCRIPTION_FAILURE';

//fetch medicalrecords
export const FETCH_MEDICAL_NOTES_REQUEST = 'FETCH_MEDICAL_NOTES_REQUEST';
export const FETCH_MEDICAL_NOTES_SUCCESS = 'FETCH_MEDICAL_NOTES_SUCCESS';
export const FETCH_MEDICAL_NOTES_FAILURE = 'FETCH_MEDICAL_NOTES_FAILURE';

//fetch Prescription
export const FETCH_PRESCRIPTIONS_REQUEST = 'FETCH_PRESCRIPTIONS_REQUEST';
export const FETCH_PRESCRIPTIONS_SUCCESS = 'FETCH_PRESCRIPTIONS_SUCCESS';
export const FETCH_PRESCRIPTIONS_FAILURE = 'FETCH_PRESCRIPTIONS_FAILURE'

///add tag
export const ADD_TAG_REQUEST = 'ADD_TAG_REQUEST';
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS';
export const ADD_TAG_FAILURE = 'ADD_TAG_FAILURE';

//get tag
export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';

//update tag
export const UPDATE_TAG_REQUEST = 'UPDATE_TAG_REQUEST';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';

//add Appointment
export const POST_APPOINTMENT_REQUEST = 'POST_APPOINTMENT_REQUEST';
export const POST_APPOINTMENT_SUCCESS = 'POST_APPOINTMENT_SUCCESS';
export const POST_APPOINTMENT_FAILURE = 'POST_APPOINTMENT_FAILURE';

//add token
export const POST_TOKEN_REQUEST = 'POST_TOKEN_REQUEST';
export const POST_TOKEN_SUCCESS = 'POST_TOKEN_SUCCESS';
export const POST_TOKEN_FAILURE = 'POST_TOKEN_FAILURE';

//add FollowUp
export const POST_FOLLOWUP_REQUEST = 'POST_FOLLOWUP_REQUEST';
export const POST_FOLLOWUP_SUCCESS = 'POST_FOLLOWUP_SUCCESS';
export const POST_FOLLOWUP_FAILURE = 'POST_FOLLOWUP_FAILURE';

//get Aviailibity
export const FETCH_AVAILABILITY_REQUEST = 'FETCH_AVAILABILITY_REQUEST';
export const FETCH_AVAILABILITY_SUCCESS = 'FETCH_AVAILABILITY_SUCCESS';
export const FETCH_AVAILABILITY_FAILURE = 'FETCH_AVAILABILITY_FAILURE';
//get patient info
export const FETCH_PATIENT_INFO_REQUEST = 'FETCH_PATIENT_INFO_REQUEST';
export const FETCH_PATIENT_INFO_SUCCESS = 'FETCH_PATIENT_INFO_SUCCESS';
export const FETCH_PATIENT_INFO_FAILURE = 'FETCH_PATIENT_INFO_FAILURE';

//add Bill
export const ADD_BILL_REQUEST = 'ADD_BILL_REQUEST';
export const ADD_BILL_SUCCESS = 'ADD_BILL_SUCCESS';
export const ADD_BILL_FAILURE = 'ADD_BILL_FAILURE';

//Add Area
export const ADD_AREA_REQUEST = 'ADD_AREA_REQUEST';
export const ADD_AREA_SUCCESS = 'ADD_AREA_SUCCESS';
export const ADD_AREA_FAILURE = 'ADD_AREA_FAILURE';

//fetch template
export const FETCH_TEMPLATES_TITLE_REQUEST = 'FETCH_TEMPLATES_TITLE_REQUEST';
export const FETCH_TEMPLATES_TITLE_SUCCESS = 'FETCH_TEMPLATES_TITLE_SUCCESS';
export const FETCH_TEMPLATES_TITLE_FAILURE = 'FETCH_TEMPLATES_TITLE_FAILURE';

//fetch area name
export const FETCH_AREA_NAMES_REQUEST = 'FETCH_AREA_NAMES_REQUEST';
export const FETCH_AREA_NAMES_SUCCESS = 'FETCH_AREA_NAMES_SUCCESS';
export const FETCH_AREA_NAMES_FAILURE = 'FETCH_AREA_NAMES_FAILURE';

//post template
export const POST_TEMPLATE_REQUEST = 'POST_TEMPLATE_REQUEST';
export const POST_TEMPLATE_SUCCESS = 'POST_TEMPLATE_SUCCESS';
export const POST_TEMPLATE_FAILURE = 'POST_TEMPLATE_FAILURE';

//post treatment
export const POST_TREATMENT_REQUEST = 'POST_TREATMENT_REQUEST';
export const POST_TREATMENT_SUCCESS = 'POST_TREATMENT_SUCCESS';
export const POST_TREATMENT_FAILURE = 'POST_TREATMENT_FAILURE';

//fetch Area
export const FETCH_AREA_REQUEST = 'FETCH_AREA_REQUEST';
export const FETCH_AREA_SUCCESS = 'FETCH_AREA_SUCCESS';
export const FETCH_AREA_FAILURE = 'FETCH_AREA_FAILURE';
//update patient
export const UPDATE_PATIENT_REQUEST = 'UPDATE_PATIENT_REQUEST';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAILURE = 'UPDATE_PATIENT_FAILURE';

//update tokenAppointment
export const UPDATE_TOKENAPPOINTMENT_REQUEST = 'UPDATE_TOKENAPPOINTMENT_REQUEST';
export const UPDATE_TOKENAPPOINTMENT_SUCCESS = 'UPDATE_TOKENAPPOINTMENT_SUCCESS';
export const UPDATE_TOKENAPPOINTMENT_FAILURE = 'UPDATE_TOKENAPPOINTMENT_FAILURE';

export const FETCH_PATIENT_BY_ID_REQUEST = 'FETCH_PATIENT_BY_ID_REQUEST'
export const FETCH_PATIENT_BY_ID_SUCCESS = 'FETCH_PATIENT_BY_ID_SUCCESS';
export const FETCH_PATIENT_BY_ID_FAILURE = 'FETCH_PATIENT_BY_ID_FAILURE';

//UPDATE patient info
export const UPDATE_PATIENT_INFO_REQUEST = 'UPDATE_PATIENT_INFO_REQUEST';
export const UPDATE_PATIENT_INFO_SUCCESS = 'UPDATE_PATIENT_INFO_SUCCESS';
export const UPDATE_PATIENT_INFO_FAILURE = 'UPDATE_PATIENT_INFO_FAILURE';


export const POST_REPORT_REQUEST = "POST_REPORT_REQUEST";
export const POST_REPORT_SUCCESS = "POST_REPORT_SUCCESS";
export const POST_REPORT_FAILURE = "POST_REPORT_FAILURE";

export const POST_PATIENTINFO_REQUEST = "POST_PATIENTINFO_REQUEST";
export const POST_PATIENTINFO_SUCCESS = "POST_PATIENTINFOSUCCESS_SUCCESS";
export const POST_PATIENTINFO_FAILURE = "POST_PATIENTINFOFAILURE_FAILURE";

