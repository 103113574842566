import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  Grid,
  Tooltip,
  TextField,
  Select,
  MenuItem,
  Button,
  Alert,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import {
  addPatientRequest,
  updatePatientRequest,
  fetchPatientByIdRequest,
} from '../../store/appActions';
import { FormBox } from './style';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';
import toast from 'src/utils/toast';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function Patient() {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isLoading);
  const patient = useSelector((state) => state.patient);
  const error = useSelector((state) => state.error);
  const clinic = useSelector((state) => state.app.clinics);


  const [initialValues, setInitialValues] = useState({
    contactNo: '',
    fullName: '',
    age: '',
    gender: '',
    // area: '',
  });

  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientByIdRequest(patientId));
    }
  }, [patientId, dispatch]);

  useEffect(() => {
    if (patientId && patient) {
      console.log('Setting initialValues:', patient);
      setInitialValues({
        contactNo: patient.contactNo || '',
        name: patient.fullName || '',
        age: patient.age || '',
        gender: patient.gender || '',
        area: patient.area || '',
      });
    }
  }, [patient, patientId]);

  const handleSubmit = async (values, { resetForm }) => {
    const token = storage.get('TOKEN');
    const decodedToken = jwtDecode(token);
    console.log("decoded token", decodedToken)
    const users = decodedToken?.user;
    const patientData = { ...values, users: Array.isArray(users) ? users : [users] };
    console.log("patient datassss", patientData)

    try {
      if (patientId) {
        const response = await new Promise((resolve, reject) =>
          dispatch(updatePatientRequest(patientId, patientData, resolve, reject)),
        );

        toast.success(response.message || 'Patient updated successfully');
      } else {
        const response = await new Promise((resolve, reject) =>
          dispatch(addPatientRequest({ data: patientData, resolve, reject })),
          console.log("addPatientData", patientData)
        );
        toast.success(response.message || 'Patient created successfully');
        navigate(`/${clinic.clinicId}/${clinic.doctorId}/Patients`);

        resetForm();
      }
    } catch (error) {
      console.error('Failed to save patient data:', error.message || error);
      toast.error('Failed to save patient data');
    }
  };

  return (
    <FormBox>
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object().shape({
            contactNo: Yup.string()
              .required("Please enter patient's contact number!")
              .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits'),
            fullName: Yup.string().required("Please enter patient's name!"),
            age: Yup.number()
              .required("Please enter patient's age!")
              .positive('Age must be a positive number'),
            gender: Yup.string().required("Please select patient's gender!"),
            // area: Yup.string().required("Please enter patient's area!"),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit, touched, errors, isValid }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
                  {patientId ? 'Update Patient' : 'Add Patient'}
                </Typography>
                {error && (
                  <Grid item>
                    <Alert severity="error">{error.message}</Alert>
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Contact No
                  </Typography>
                  <Tooltip title="Enter patient's contact number">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="contactNo"
                      value={values.contactNo}
                      onChange={handleChange}
                      error={touched.contactNo && Boolean(errors.contactNo)}
                      helperText={touched.contactNo && errors.contactNo}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Name
                  </Typography>
                  <Tooltip title="Enter patient's full name">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="fullName"
                      value={values.fullName}
                      onChange={handleChange}
                      error={touched.fullName && Boolean(errors.fullName)}
                      helperText={touched.fullName && errors.fullName}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Age
                  </Typography>
                  <Tooltip title="Enter patient's age">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="age"
                      value={values.age}
                      onChange={handleChange}
                      type="number"
                      inputProps={{ min: 0 }}
                      error={touched.age && Boolean(errors.age)}
                      helperText={touched.age && errors.age}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Gender
                  </Typography>
                  <Tooltip title="Select patient's gender">
                    <Select
                      variant="outlined"
                      fullWidth
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      error={touched.gender && Boolean(errors.gender)}
                      helperText={touched.gender && errors.gender}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </Tooltip>
                </Grid>
                {/* <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Area
                  </Typography>
                  <Tooltip title="Enter patient's residential area">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="area"
                      value={values.area}
                      onChange={handleChange}
                      error={touched.area && Boolean(errors.area)}
                      helperText={touched.area && errors.area}
                    />
                  </Tooltip>
                </Grid> */}
                <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '30px' }}>
                  <Tooltip title="Save the patient" arrow>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ textAlign: 'center' }}
                      disabled={!isValid}
                    >
                      {isLoading ? 'Saving...' : patientId ? 'Update Patient' : 'Save Patient'}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </FormBox>
  );
}

export default Patient;
