

import React from 'react';
import Welcome from '../../components/patient/Welcome';
import Information from '../../components/patient/Information';
import Bill from '../../components/RightSidebar/Bill';
import { Container } from '@mui/material';
import PatientList from '../../components/patient/PatientList';

function BillContainer() {
  return (
    <>
      {/* <Welcome /> */}
      <Information />
      <Container maxWidth="lg">
        <Bill />
      </Container>
    </>
  );
}
export default PatientList(BillContainer);