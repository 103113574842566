import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormBox } from './style';
import {
  Grid,
  Typography,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import AppTimePicker from 'src/components/shared/Form/Timepicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { postTreatmentRequest } from '../../store/appActions';
import { jwtDecode } from 'jwt-decode';
import storage from 'src/utils/storageUtils';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import toast from 'src/utils/toast';



function Treatment() {
  const dispatch = useDispatch();
  const clinic = useSelector((state) => state.app.clinics.currentClinic);
  const location = useLocation();
  const { patient, doctorDefaultTime } = location.state || {}; // Retrieve doctor's default time from location state

  const [sittings, setSittings] = useState(0);
  const [dateTimes, setDateTimes] = useState([]);
  const [treatmentType, setTreatmentType] = useState('visiting');
  const [treatmentName, setTreatmentName] = useState('');
  const [total, setTotal] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [selectedDays, setSelectedDays] = useState([]);
  const [errors, setErrors] = useState({});


  const validateForm = () => {
    const newErrors = {};

    if (!treatmentName.trim()) {
      newErrors.treatmentName = 'Treatment name is required';
    }
    if (!total || isNaN(total) || parseInt(total) <= 0) {
      newErrors.total = 'Total must be a positive number';
    }
    if (!remaining || isNaN(remaining) || parseInt(remaining) < 0) {
      newErrors.remaining = 'Remaining must be zero or a positive number';
    }
    if (treatmentType === 'sitting') {
      if (!dateTimes[0]?.date) {
        newErrors.firstSittingDate = 'Date for the first treatment is required';
      }
      if (!dateTimes[0]?.time) {
        newErrors.firstSittingTime = 'Time for the first treatment is required';
      }
    } else if (treatmentType === 'visiting') {
      if (selectedDays.length === 0) {
        newErrors.selectedDays = 'At least one day must be selected for visits';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const [time, setTime] = useState(() => {
    return doctorDefaultTime
      ? dayjs(doctorDefaultTime, 'HH:mm')
      : dayjs()
  });

  useEffect(() => {
    if (doctorDefaultTime) {
      setTime(dayjs(doctorDefaultTime, 'HH:mm'));
    }
  }, [doctorDefaultTime]);

  const currentTimeFormatted = dayjs().format('HH:mm');
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const handleSittingsChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSittings(value);
    setDateTimes(Array(value).fill({ date: null, time: null }));
  };

  const handleDateChange = (index, date) => {
    const newDateTimes = [...dateTimes];
    newDateTimes[index] = { ...newDateTimes[index], date };
    setDateTimes(newDateTimes);
  };

  const handleTimeChange = (index, time) => {
    const newDateTimes = [...dateTimes];
    newDateTimes[index] = { ...newDateTimes[index], time };
    setDateTimes(newDateTimes);
  };

  const handleTreatmentTypeChange = (event) => {
    setTreatmentType(event.target.value);
  };

  const handleTreatmentNameChange = (event) => {
    setTreatmentName(event.target.value);
    if (errors.treatmentName && event.target.value.trim()) {
      setErrors(prev => ({ ...prev, treatmentName: undefined }));
    }

  };

  const handleChange = (event, type) => {
    const rawValue = event.target.value;
    const sanitizedValue = rawValue.replace(/^0+(?=\d)/, "");

    if (type === "total") {
      if (type === "total" && errors.total && !isNaN(sanitizedValue) && parseInt(sanitizedValue) > 0) {
        setErrors(prev => ({ ...prev, total: undefined }));
      }

      setTotal(sanitizedValue);
      if (remaining === 0 || ' ') {
        setRemaining(sanitizedValue)
      }

    } else if (type === "remaining") {
      if (type === "remaining" && errors.remaining && !isNaN(sanitizedValue) && parseInt(sanitizedValue) > 0) {
        setErrors(prev => ({ ...prev, remaining: undefined }));
      }
      setRemaining(sanitizedValue);
    }
  };


  const handleDayChange = (day) => {
    if (errors.selectedDays && selectedDays.length >= 0) {
      setErrors(prev => ({ ...prev, selectedDays: undefined }));
    }

    setSelectedDays((prevDays) =>
      prevDays.includes(day) ? prevDays.filter((d) => d !== day) : [...prevDays, day],
    );
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const token = storage.get('TOKEN');
      if (!token) throw new Error('Token not found.');

      const decodedToken = jwtDecode(token);
      const user = decodedToken?.user;

      if (!user) throw new Error('User data not available.');
      if (!patient?.id) throw new Error('Patient data or ID is not available.');

      const treatment = {
        name: treatmentName,
        total: parseInt(total),
        remaining: parseInt(remaining),
        status: 'ACTIVE',
        patient: patient.id,
        user,
        clinic: clinic.id,
        tokenAppointment: patient.tokenappointment,
      };

      if (treatmentType === 'sitting') {
        treatment.treatmentType = 'sitting';
        treatment.dateTimes = dateTimes
          .filter((dt) => dt.date && dt.time)
          .map((dt) => ({
            date: dt.date ? dayjs(dt.date).format('YYYY-MM-DD') : null,
            time: dt.time ? dayjs(dt.time).format('HH:mm') : null,
          }));
      } else if (treatmentType === 'visiting') {
        treatment.treatmentType = 'visiting';
        treatment.days = selectedDays;
        treatment.time = time;
      }
      const promise = await new Promise((resolve, reject) => {
        dispatch(postTreatmentRequest({ data: treatment, resolve, reject }))
      })
      const response = await promise;
      console.log('Before toast:', response);
      toast.success(response.message || 'Treatment created successfully');

    } catch (error) {
      console.error('Error submitting treatment:', error);
    }
  };

  return (
    <FormBox marginTop="20px">
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
          Treatment
        </Typography>

        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            Treatment Type
          </Typography>
          <Tooltip title="Select the type of treatment (Visiting or Sitting)">
            <Select
              fullWidth
              value={treatmentType}
              onChange={handleTreatmentTypeChange}
              variant="outlined"
            >
              <MenuItem value="visiting">Visiting</MenuItem>
              <MenuItem value="sitting">Sitting</MenuItem>
            </Select>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        spacing={2}
        sx={{ padding: '30px', position: 'relative', bottom: 40 }}
      >
        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            Treatment Name
          </Typography>
          <Tooltip title="Enter the treatment name required">
            <TextField
              variant="outlined"
              fullWidth
              value={treatmentName}
              onChange={handleTreatmentNameChange}
              error={!!errors.treatmentName}
              helperText={errors.treatmentName}
            />
          </Tooltip>
        </Grid>

        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            Total
          </Typography>
          <Tooltip title="Enter the total number of sittings or visits required">
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              value={total}
              onChange={(event) => handleChange(event, "total")}
              error={!!errors.total}
              helperText={errors.total}
            />

          </Tooltip>
        </Grid>
        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            Remaining
          </Typography>
          <Tooltip title="Enter the total number of sittings or visits required">
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              value={remaining}
              onChange={(event) => handleChange(event, "remaining")}
              error={!!errors.remaining}
              helperText={errors.remaining}
            />
          </Tooltip>
        </Grid>

        {treatmentType === 'visiting' && (
          <>
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                When Visit
              </Typography>
              <Tooltip title="Select the days of the week for visits">
                <Grid
                  item
                  container
                  spacing={1}
                  sx={{ position: 'relative', bottom: '30px', left: '12%' }}
                >
                  {daysOfWeek.map((day, index) => (
                    <Grid item key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDays.includes(day)}
                            onChange={() => handleDayChange(day)}
                            error={!!errors.selectedDays}

                          />
                        }
                        label={day}
                      />
                    </Grid>
                  ))}
                </Grid>
                {errors.selectedDays && <Typography color="error" sx={{ position: "relative", bottom: "25px" }}>{errors.selectedDays}</Typography>}

              </Tooltip>

            </Grid>
            <Grid item sx={{ position: 'relative', bottom: 50 }}>
              <Typography variant="h5" fontWeight="bold" sx={{ position: 'relative', top: 15 }}>
                Visit Time
              </Typography>
              <Tooltip title="Select the time for visits">
                <AppTimePicker
                  fullWidth
                  value={time}
                  onChange={(newTime) =>
                    setTime(newTime)}
                  format="HH:mm"
                  placeholder={currentTimeFormatted}
                />
              </Tooltip>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
              <Tooltip title="Save the visiting treatment">
                <Button variant="contained" onClick={handleSubmit}>
                  Save Visiting Treatment
                </Button>
              </Tooltip>
            </Grid>
          </>
        )}

        {treatmentType === 'sitting' && (
          <>
            <Grid item>
              <Stack >
                <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                  Select Date for first Treatment                 </Typography>
                <Tooltip title="Select the date for sitting">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker

                      onChange={(date) => handleDateChange(0, date)}
                      error={!!errors.firstSittingDate}
                      helperText={errors.firstSittingDate}


                    />
                  </LocalizationProvider>
                </Tooltip>

              </Stack>

            </Grid>

            <Grid item>
              <Stack >
                <Typography variant="h5" fontWeight="bold" >
                  Select Time for first Treatment
                </Typography>
                <Tooltip title="Select the time for sitting">
                  <AppTimePicker
                    // sx={{ width: '35%' }}
                    value={time}
                    onChange={(newTime) => handleTimeChange(0, dayjs(newTime).format('HH:mm'))}
                    error={!!errors.firstSittingTime}
                    helperText={errors.firstSittingTime}
                  />
                </Tooltip>

              </Stack>
            </Grid>
            <Grid container spacing={2} justifyContent="center" mt={5}>
              <Tooltip title="Save the sitting treatment">
                <Button variant="contained" onClick={handleSubmit}>
                  Save Sitting Treatment
                </Button>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>
    </FormBox>
  );
}

export default Treatment;
