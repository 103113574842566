import React from "react";
import Welcome from "../../components/patient/Welcome";
import Tag from "../../components/RightSidebar/Tag";
import { Container } from "@mui/material";
// import PatientInfo from "../../components/patient/PatientInfo";
import Information from "../../components/patient/Information";
import PatientList from '../../components/patient/PatientList';

function TaskContainer() {
    return (
        <>
            {/* <Welcome /> */}
            <Information />

            <Container maxWidth='lg'>
                <Tag />
            </Container>
        </>
    )
}
export default PatientList(TaskContainer);