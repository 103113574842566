import React from 'react';
import PatientInfo from '../../components/RightSidebar/PatientInfo';
import Information from '../../components/patient/Information';
import { Container } from '@mui/material';


function PatientInfoContainer() {
    return (
        <>
            <Information />
            <Container maxWidth='lg'>
                <PatientInfo />
            </Container>
        </>
    )
}
export default PatientInfoContainer
