import React, { useRef } from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


function SharePrescription() {
  const location = useLocation();
  const navigate = useNavigate();
  const printRef = useRef();
  const { record, patientData } = location.state || {};
  const clinic = useSelector((state) => state.app.clinics.currentClinic);

  const { title, prescription } = location.state || {};


  const handlePrint = () => {
    if (printRef.current) {
      window.print();
    }
  };


  const handleShareViaWhatsApp = () => {
    const url = window.location.href;
    const message = `Check out this prescription: ${title}\n\n${prescription}\n\n${url}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => alert('URL copied to clipboard!'))
      .catch((err) => console.error('Failed to copy URL:', err));
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Box ref={printRef} sx={{ padding: '20px', border: '1px solid #ccc' }}>
          <Typography variant="h4" textAlign="center" mb={3} pb={2} borderBottom={2} sx={{ fontWeight: 'bold' }}>
            Prescription Details
          </Typography>


          <Grid container flexDirection="row" sx={{ backgroundColor: "lightgray" }} justifyContent="space-between" borderRadius={1} padding={2}>
            <Grid item >
              <Typography variant='h4' color='primary' fontWeight="bold"  >
                Clinic
              </Typography>

              <Typography variant='h5' fontWeight="bold">
                {clinic?.clinicName || 'N/A'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h4' color='primary' fontWeight="bold" >
                Address
              </Typography>

              <Typography variant='h5' fontWeight="bold">
                {clinic?.address || 'N/A'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container flexDirection="row" sx={{ backgroundColor: "lightgray" }} justifyContent="space-between" mt={1} borderRadius={1} padding={2}>
            <Grid item >
              <Typography variant='h4' color='primary' fontWeight="bold"  >
                Patient Name
              </Typography>

              <Typography variant='h5' fontWeight="bold">
                {patientData?.name || 'N/A'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h4' color='primary' fontWeight="bold" >
                Issue
              </Typography>

              <Typography variant='h5' fontWeight="bold">
                {patientData?.symptoms || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{
            backgroundColor: "white", boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)'
          }} justifyContent="center" alignItems="center" mt={1} borderRadius={1} padding={2} pb={10}>
            <Grid item >
              <Typography variant='h4' color='primary' fontWeight="bold" textAlign="center" mb={1}>
                Prescription
              </Typography>

              <Typography variant='h5' fontWeight="bold" textAlign="center">
                {record?.prescription || 'N/A'}
              </Typography>
            </Grid>

          </Grid>

        </Box>

      </Grid >

      <Grid item container spacing={2} sx={{ marginTop: '20px' }} justifyContent="center">
        <Grid item>
          <Button variant="contained" color="primary" onClick={handlePrint}>
            Print
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="success" onClick={handleShareViaWhatsApp}>
            Share via WhatsApp
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={handleCopyUrl}>
            Copy URL
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        </Grid>
      </Grid>
    </Grid >
  );
}

export default SharePrescription;
