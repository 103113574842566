import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Stack,
  Popover,
  IconButton,
  Grid,
  Box,
  Drawer,
  Typography,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AppGrid from 'src/components/App/AppGrid';
import PickerWithButtonField from 'src/components/App/DatePicker';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchPatientsRequest,
  deletePatientRequest,
} from 'src/modules/app/store/appActions';
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';

function PatientsContainer() {
  const [open, setOpen] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();
  const clinic = useSelector((state) => state.app.clinics);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = storage.get('TOKEN');
        const response = await new Promise((resolve, reject) =>
          dispatch(fetchPatientsRequest(token, resolve, reject)),

        );
        console.log('Fetched Patients:', response);
        if (response && response.length) {
          setPatients(response);
        }
      } catch (error) {
        toast.error('Failed to fetch patients');
        console.error('Error fetching patients:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleActionMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedPatientId(id);
  };

  const handleDeletePatient = async () => {
    try {
      const response = await new Promise((resolve, reject) =>
        dispatch(deletePatientRequest(selectedPatientId, resolve, reject)),
      );
      if (response) {
        setPatients(patients.filter((patient) => patient.id !== selectedPatientId));
        handleCloseMenu();
        toast.success('Patient deleted successfully');
      } else {
        toast.error('Failed to delete patient');
      }
    } catch (error) {
      toast.error('Failed to delete patient');
      console.error('Error deleting patient:', error);
    }
  };

  const handleViewDetails = (dataIndex) => () => {
    const patient = patients[dataIndex];
    setSelectedPatient(patient);
    setSelectedPatientId(patient?.id);
    setDrawerOpen(true);
    handleCloseMenu();
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleAttendNow = () => {
    handleCloseMenu();

    if (clinic && clinic.clinicId && clinic.doctorId && selectedPatientId) {
      navigate(`/${clinic.clinicId}/${clinic.doctorId}/patienttokens/${selectedPatientId}`, {
        state: { patientData: selectedPatientId },
      });
      console.log("selected patientdata and id", selectedPatientId)
    } else {
      toast.error('Clinic information is not available');
    }
  };

  const handleEditPatient = () => {
    if (clinic && clinic.clinicId && selectedPatientId) {
      navigate(`/${clinic.clinicId}/${clinic.doctorId}/patient/${selectedPatientId}`);
    } else {
      toast.error('Patient or clinic information is missing');
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedPatient(null);
  };

  const columns = [
    { label: 'Name', name: 'fullName' },
    { label: 'Gender', name: 'gender' },
    { label: 'Age', name: 'age' },
    { label: 'Area', name: 'area' },
    {
      label: '',
      name: 'Action',
      options: {
        customBodyRenderLite: (dataIndex) => (
          <>
            <IconButton onClick={(event) => handleActionMenu(event, patients[dataIndex]?.id)}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              // onClose={handleCloseMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{ sx: { width: 170 } }}
            >
              <MenuItem onClick={handleViewDetails(dataIndex)}>
                <Stack spacing={1} direction="row">
                  <VisibilityIcon />
                  <span>View Details</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleAttendNow}>
                <Stack spacing={1} direction="row">
                  <CheckCircleIcon />
                  <span>Attend Now</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleEditPatient}>
                <Stack spacing={1} direction="row">
                  <EditIcon />
                  <span>Edit</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleDeletePatient} sx={{ color: 'error.main' }}>
                <Stack spacing={1} direction="row">
                  <DeleteIcon />
                  <span>Delete</span>
                </Stack>
              </MenuItem>
            </Popover>
          </>
        ),
      },
    },
  ];

  return (
    <>
      <Box sx={{ marginBottom: 2, position: 'relative', bottom: '40px' }}>
        <Grid container spacing={3}>
          <Grid item xs={10} container>
            <Grid item xs={6}>
              <Stack spacing={1} direction="row">
                <PickerWithButtonField />
              </Stack>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={() => navigate(`/${clinic.clinicId}/${clinic.doctorId}/patient`)}>
                New Patient
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 2, position: 'relative', bottom: '40px' }}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <AppGrid
              columns={columns}
              data={patients}
              selectableRows="none"
              onFilterChange={() => { }}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => { }}
              onSort={() => { }}
              onClearFilters={() => { }}
              title=""
              titleProps={{}}
              pagingType="table"
            />
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
        <Box sx={{ width: 350, padding: 2 }}>
          {selectedPatient && (
            <>
              <Typography variant="h6" gutterBottom>
                Patient Details
              </Typography>
              <Typography>Name: {selectedPatient.fullName}</Typography>
              <Typography>Gender: {selectedPatient.gender}</Typography>
              <Typography>Age: {selectedPatient.age}</Typography>
              <Typography>Area: {selectedPatient.area}</Typography>
              <Button variant="contained" sx={{ marginTop: 2 }} onClick={closeDrawer}>
                Close
              </Button>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
}

export default PatientsContainer;
