import React, { useEffect, useState } from 'react';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { palette } from 'src/config/theme';
import AllPatient from 'src/components/App/PatientInfo/AllPatient';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ButtonComponent from 'src/components/shared/Button';

export const PatientBox = styled(Box)({
  backgroundColor: palette.white,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  width: '100%',
  height: '100%',
  borderRadius: '16px',
  overflow: 'auto',
  borderEndEndRadius: 0,
  borderTopRightRadius: 0,
  transition: 'width 0s linear 1s'

});

export const PatientModal = styled(Box)({
  position: 'absolute',
  top: '-35px',
  right: '-20px',
  zIndex: 1,
  height: '150vh',
  transition: 'width 1s',
});

export const PatientToggleButton = styled(PatientModal)({
  backgroundColor: palette.white,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  borderRadius: '50%',
  left: '-25px',
  top: '10px',
  padding: '8px',
  width: '40px',
  height: '40px',
});

const PatientList = (WrapperComponent) => (props) => {
  const PatientListModel = React.memo(() => {
    const [isShowPatientList, setIsShowPatientList] = useState(false);
    const [activePatientIndex, setActivePatientIndex] = useState(0);

    useEffect(() => {
      const sidebar = document.getElementById('header-sidebar-toggle-btn');
      sidebar.addEventListener('click', () => setIsShowPatientList(false));

      return () => sidebar.removeEventListener('click', () => setIsShowPatientList(false));
    }, []);

    return (
      <>
        <Container sx={{ position: 'relative', margin: 0, maxWidth: '100% !important' }}>
          <PatientModal width={isShowPatientList ? '16%' : 0}>
            <PatientToggleButton onClick={() => setIsShowPatientList(!isShowPatientList)}>
              {isShowPatientList ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </PatientToggleButton>

            <PatientBox height="80vh">
              {/* Pass activePatientIndex and setActivePatientIndex to AllPatient */}
              <AllPatient
                activePatientIndex={activePatientIndex}
                setActivePatientIndex={setActivePatientIndex}
              />
            </PatientBox>
          </PatientModal>
        </Container>
        <WrapperComponent {...props} />
      </>
    );
  });
  return <PatientListModel />;
};

export default PatientList;
