import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Grid, Tooltip, Button, Box, Typography, TextField, Autocomplete } from '@mui/material';
import InputAutocomplete from 'src/components/shared/Form/AutoComplete';
import { FormBox } from './style';
import { addAreaRequest, fetchAreanameRequest } from 'src/modules/app/store/appActions';
import toast from 'src/utils/toast';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';


const AreaData = [
  { label: "Odhav", value: "odhav" },
  { label: "Iskon", value: "iskon" },
  { label: "CG Road", value: "cg road" },
  { label: "Narol", value: "narol" },
  { label: "SG Highway", value: "sg_highway" },
  { label: "Bopal", value: "bopal" },
];



function Area() {
  const dispatch = useDispatch();
  const location = useLocation();
  const clinic = useSelector((state) => state.app.clinics.currentClinic);
  console.log("current clinic:", clinic.id)
  const { patient } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [area, setArea] = useState(null);




  const handleSubmit = async (values, { resetForm }) => {

    try {
      const token = storage.get('TOKEN');
      const decodedToken = jwtDecode(token);
      const user = decodedToken?.user;

      if (!user) throw new Error('User information is missing. Please log in again.');
      if (!clinic || !clinic.id) throw new Error('Clinic information is missing.');

      const area = {
        ...values,
        user,
        clinic: clinic.id,
        patient: patient.id,
        area: values.area?.value,
      };
      const promise = await new Promise((resolve, reject) => {
        dispatch(addAreaRequest({ data: area, resolve, reject }));
      });
      const response = await promise;
      toast.success(response.message || 'Area Added successfully');
      resetForm();

    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error('Failed to save area: ' + error.response.message);
      } else {
        toast.error('An area with the same user, clinic, and patient already exists');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormBox sx={{ marginTop: '20px' }}>
      <Formik
        initialValues={{ area: null }}
        validationSchema={Yup.object().shape({
          area: Yup.object().required('Please select or type an area'),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, touched, errors }) => (
          <Form noValidate>
            <Box sx={{ marginTop: '10px', padding: '20px' }}>
              <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Typography variant="h4" color="#393A96" fontWeight="bold">
                  Location
                </Typography>
                <Typography variant="h5" fontWeight="bold">
                  Area
                </Typography>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Tooltip title="Select or type to choose an area">
                      <InputAutocomplete
                        variant="outlined"
                        fullWidth
                        name="area"
                        value={values.area}
                        onChange={(newValue) => {
                          console.log("selected value:", newValue)
                          setFieldValue('area', newValue);
                        }}
                        options={AreaData}
                        error={Boolean(touched.area && errors.area)}
                        helperText={touched.area && errors.area}

                      />

                    </Tooltip>
                  </Grid>
                  <Grid item container justifyContent="center" sx={{ marginTop: '20px' }}>
                    <Tooltip title="Save the area">
                      <Button type="submit" variant="contained" disabled={isLoading}>
                        Save Area
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </FormBox>
  );
}

export default Area;



