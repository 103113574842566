import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import theme from 'src/config/theme';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentClinics, fetchAvailabilityRequest } from '../../store/appActions';
import Select from 'src/components/shared/Form/Select';

const Clinics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clinic = useSelector((state) => state.app.clinics);
  const clinics = clinic?.data || [];


  // Prepare the clinic list for the dropdown
  const clinicList = clinics.map((clinic) => ({
    ...clinic,
    label: clinic.clinicName,
    value: clinic.id,
  }));


  // Function to handle clinic selection
  const setClinicIdOnUrl = (id) => {
    const selectedClinic = clinicList.find((clinic) => clinic.id === id);
    if (selectedClinic) {
      dispatch(setCurrentClinics({ clinicId: selectedClinic.id, doctorId: selectedClinic.user }));
      navigate(`/${selectedClinic.id}/${selectedClinic.user}`);

      // Fetch availability for the selected clinic
      dispatch(fetchAvailabilityRequest(selectedClinic.id));
    }
  };

  return (
    <Container sx={{ minHeight: '100px', paddingBottom: 5 }}>
      <Box
        sx={{
          padding: '2px 4px',
          width: '35%',
          // backgroundColor: 'white',
          // borderRadius: '16px',
          // boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
          display: 'flex',
          flexDirection: 'column',
          // position: 'relative',
          // bottom: '20px',
          // right: '2.5%',
          // justifyContent: 'space-between',
          // gap: '16px',
          // [theme.breakpoints.down('lg')]: {
          //   height: '20vh',
          // },
          // [theme.breakpoints.down('md')]: {
          //   height: '20vh',
          // },
          // [theme.breakpoints.down('sm')]: {
          //   height: '20vh',
          // },
        }}
      >
        <Stack >
          {/* <Typography variant="h4" fontWeight={600}>
            Choose your clinic
          </Typography> */}
          <Box   >
            <Select
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px',
                overflow: 'hidden',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
                // padding: '10px',
                padding: '4px 10px'
              }}
              variant="outlined"
              // fullWidth
              value={clinic.currentClinic?.id || clinics[0]?.id}
              onChange={(event) => setClinicIdOnUrl(event.target.value)}
              options={clinicList}
              showNone={false}
            />
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default Clinics;
