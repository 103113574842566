import React from "react";
import Welcome from "../../components/patient/Welcome";
import Information from "../../components/patient/Information";
import PatientTokens from "../../components/RightSidebar/PatientTokens";
import { Container, Box, Grid } from "@mui/material";


function PatientTokenContainer() {
    return (
        <>
            {/* <Welcome/> */}

            <Container maxWidth='lg'>
                {/* <Information/> */}
                <PatientTokens />

            </Container>

        </>
    )
}
export default PatientTokenContainer