import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Box, Typography, Grid, Tooltip, Button, TextField } from '@mui/material';
import { Formik } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormBox } from "./style";
import Spacer from "src/components/shared/Spacer";
import Autocomplete from '@mui/material/Autocomplete';
import { postAppointmentRequest } from 'src/modules/app/store/appActions';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';
import toast from 'src/utils/toast';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

const TIME_SLOTS = ['09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '04:00 PM', '05:00 PM'];
const SYMPTOMS_OPTIONS = [
  { label: 'Fever', value: 'fever' },
  { label: 'Cough', value: 'cough' },
];

function PatientAppointment() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.app.isLoading);
  const [selectedTime, setSelectedTime] = useState(null);
  const clinic = useSelector((state) => state.app.clinics);
  const location = useLocation();
  const { patient } = location.state || {};


  useEffect(() => {
    if (!patient) {
      toast.error('No patient selected. Please select a patient.');
    }
    // Add any necessary action to fetch clinic data if it's not available
  }, [patient]);

  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };

  const formatTime = (time) => {
    return dayjs(time, 'hh:mm A').format('HH:mm:ss');
  };

  const handleSaveAppointment = async (values, { resetForm }) => {
    try {
      const token = storage.get('TOKEN');
      const decodedToken = jwtDecode(token);
      const user = decodedToken?.user;


      if (!user) {
        throw new Error('User information is missing. Please log in again.');
      }

      if (!clinic || !clinic.clinicId) {
        throw new Error('Clinic information is missing.');
      }

      const tokenappointment = {
        // ...values,
        addnotes: values.symptoms,
        clinic: clinic.clinicId,
        user,
        patient: patient.id,
        scheduleType: 'appointment',
        // date: values.date,
        date: values.date,
        time: formatTime(values.time),
      };


      const promise = new Promise((resolve, reject) => {
        dispatch(postAppointmentRequest({
          data: tokenappointment,
          resolve,
          reject,
        }));
      });

      const response = await promise;
      toast.success(response.message || 'Appointment created successfully');
      resetForm();
    } catch (error) {
      console.error('Error in handleSaveAppointment:', error);
      toast.error('Failed to add appointment: ' + (error.message || error));
    }
  };

  return (
    <FormBox sx={{ marginTop: '20px' }}>
      <Formik
        initialValues={{
          date: null,
          time: '',
          symptoms: ''
        }}
        validationSchema={Yup.object().shape({
          date: Yup.date().required('Please select a date!'),
          time: Yup.string().required('Please select a time slot!'),
          symptoms: Yup.string().required('Please enter symptoms!')
        })}
        onSubmit={handleSaveAppointment}
      >
        {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Typography
                variant="h4"
                color='#393A96'
                fontWeight='bold'
                sx={{ marginLeft: '2%' }}
              >
                Appointment
              </Typography>

              <Grid item>
                <Typography variant="h5" fontWeight='bold'>
                  Select Date
                </Typography>
                <Tooltip title="Select the date for the appointment">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      value={values.date ? dayjs(values.date) : null}
                      onChange={(newDate) => setFieldValue('date', newDate?.toISOString())}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Tooltip>
                {touched.date && errors.date && (
                  <Typography color="error" variant="body2">{errors.date}</Typography>
                )}
              </Grid>

              <Grid item>
                <Typography variant="h5" fontWeight='bold'>
                  Select Time Slot
                </Typography>
                <Grid container spacing={1} sx={{ marginTop: '5px', marginLeft: '0.5%' }}>
                  {TIME_SLOTS.map((time, index) => (
                    <React.Fragment key={index}>
                      <Tooltip title={`Select the appointment time for ${time}`}>
                        <Button
                          variant='outlined'
                          sx={{
                            backgroundColor: selectedTime === time ? 'primary.main' : 'transparent',
                            color: selectedTime === time ? '#fff' : 'inherit',
                            '&:hover': {
                              backgroundColor: selectedTime === time ? 'primary.dark' : 'transparent',
                            },
                          }}
                          onClick={() => {
                            setFieldValue('time', time);
                            handleTimeClick(time);
                          }}
                        >
                          {time}
                        </Button>
                      </Tooltip>
                      <Spacer x={2} />
                    </React.Fragment>
                  ))}
                </Grid>
                {touched.time && errors.time && (
                  <Typography color="error" variant="body2">{errors.time}</Typography>
                )}
              </Grid>

              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Symptoms
                </Typography>
                <Tooltip title="Enter or select symptoms for the appointment">
                  <Autocomplete
                    variant="outlined"
                    options={SYMPTOMS_OPTIONS}
                    value={values.symptoms ? { label: values.symptoms, value: values.symptoms } : null}
                    onChange={(event, value) => setFieldValue('symptoms', value?.value || '')}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={touched.symptoms && Boolean(errors.symptoms)}
                        helperText={touched.symptoms && errors.symptoms}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Tooltip>
                {touched.symptoms && errors.symptoms && (
                  <Typography color="error" variant="body2">{errors.symptoms}</Typography>
                )}
              </Grid>

              <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '10px' }}>
                <Tooltip title="Save the appointment">
                  <Button type="submit" variant='contained' sx={{ textAlign: 'center' }} disabled={isLoading}>
                    Save Appointment
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </FormBox>
  );
}

export default PatientAppointment;