import React, { useEffect, useState } from 'react';
import { Grid, Typography, Tooltip, Box } from '@mui/material';
import { Button } from 'src/components/shared';
import SwitchComponent from 'src/components/shared/Form/Switch';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';
import toast from 'src/utils/toast';
import { FormBox, ViewBox } from './style';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'src/components/shared/Form/Select';
import * as Yup from 'yup';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import {
    postReportRequest
} from '../../store/appActions';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function Report() {
    const Reports = [
        { value: 'blood report', label: 'Blood Report' },
        { value: 'crm', label: 'CRM' },
        { value: 'cardio', label: 'Cardio' },
        { value: 'x-ray', label: 'X-Ray' },
    ];
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { patient } = location.state || {};
    const handleSubmit = async (values, { resetForm }) => {
        try {
            const token = storage.get('TOKEN');
            const decodedToken = jwtDecode(token);
            const user = decodedToken?.user;
            if (!user) throw new Error('User data not available.');
            if (!patient?.id) throw new Error('Patient data or ID is not available.');

            const formData = new FormData();
            formData.append('patient', patient.id);
            formData.append('typesOfReports', values.report);
            formData.append('user', user);

            if (values.files && values.files.length > 0) {
                values.files.forEach(file => {
                    console.log("Appendinggg file:", file);
                    formData.append('report', file);
                    console.log(`Appended file: ${file.name}, size: ${file.size} , ${file}`);
                });
            } else {
                throw new Error('No file selected');
            }
            dispatch(postReportRequest(formData));
            resetForm();
        } catch (error) {
            console.error("Full error object:", error);

            const errorMessage = error?.response?.data?.message || error?.message || 'Unknown error';

            toast.error('Failed to add report: ' + errorMessage);
        }
    };
    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                initialValues={{ report: '', files: [] }}
                validationSchema={Yup.object().shape({
                    report: Yup.string().required('Please select report type'),
                    files: Yup.array().min(1, 'Please upload at least one file').required('Please upload a file').test('fileSize', 'File is too large', value => {
                        // Optional: Validate file size if needed
                        return value?.every(file => file.size <= 5 * 1024 * 1024); // 5MB max file size
                    })
                        .test('fileType', 'Unsupported file type', value => {
                            // Optional: Validate file type (example for image/pdf)
                            return value?.every(file => ['image/jpeg', 'image/png', 'application/pdf'].includes(file.type));
                        })

                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleBlur, touched, errors, setFieldValue }) => (
                    <Form noValidate>
                        <Box sx={{ marginTop: '10px', padding: '20px' }}>
                            <Grid container direction="column" spacing={2} sx={{ padding: '20px' }}>
                                <Typography variant="h4" color="#393A96" fontWeight="bold">
                                    Report
                                </Typography>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold" mb={0.7}>
                                        Select Report Type
                                    </Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        name="report"
                                        value={values.report}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Select a report"
                                        options={Reports}
                                        optValue="value"
                                        error={touched.report && Boolean(errors.report)}
                                        helperText={touched.report && errors.report}
                                    />
                                </Grid>

                                <Grid item>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload files
                                        <VisuallyHiddenInput
                                            type="file"
                                            onChange={(event) => {
                                                const files = [...event.target.files]; // Convert FileList to Array
                                                console.log("Selected files:", files);
                                                setFieldValue("files", files);
                                            }}
                                            multiple
                                        />
                                    </Button>


                                    {/* Display file validation error */}
                                    {touched.files && errors.files && (
                                        <Typography variant="body2" color="error" sx={{ marginTop: '8px' }}>
                                            {errors.files}
                                        </Typography>
                                    )}
                                </Grid>

                                <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                                    <Tooltip title="Save Report" arrow>
                                        <Button type="submit" variant="contained">
                                            Save Report
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                )}
            </Formik>
        </FormBox>
    );
}
export default Report;