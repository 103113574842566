import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Typography, Grid, Tooltip, TextField, Select, MenuItem, Button,Box, Chip, FormControl,InputLabel,OutlinedInput} from '@mui/material';
import { Formik } from 'formik';
import { addTaskRequest, fetchStaffsRequest } from '../../store/appActions';
import { FormBox } from './style';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';
import toast from 'src/utils/toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}


function Task() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.isLoading);

  const location = useLocation();
  const navigate = useNavigate();
    const [staffs, setStaffs] = useState([]);
  
  const { patient } = location.state || {};
  const clinic = useSelector((state) => state.app.clinics);

  
  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN');
      const response = await new Promise((resolve, reject) =>
        dispatch(fetchStaffsRequest(token, resolve, reject)),
      );
      if (response && response.length) {
        setStaffs(response);
      }
    };
    fetchData();
  }, [dispatch]);


  const handleSubmit = async (values, { resetForm }) => {
    const token = storage.get('TOKEN');
    const decodedToken = jwtDecode(token);
    const user = decodedToken?.user;

    const task = { ...values, user };

    const promise = new Promise((resolve, reject) => {
      dispatch(addTaskRequest({ data: task, resolve, reject }));
    });

    try {
      const response = await promise;
      toast.success(response.message || 'Task added successfully');
      navigate(`/${clinic.clinicId}/${clinic.doctorId}/tasks`)
      resetForm();
    } catch (error) {
      toast.error('Failed to add task: ' + (error.message || error));
      console.error('Failed to add task:', error.message || error);
    }
  };

  return (
    <FormBox>
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Formik
          initialValues={{
            name: '',
            assigned: [],
            dueDate:  new Date().toISOString().split('T')[0],
            priority: 'medium',
            // caseID: '',
            tokenOrAppointment: 'token',
            description: '',
            // status: 'pending',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Please enter the task name!'),
            assigned: Yup.array().min(1, 'Please select at least one assignee!'),
            dueDate: Yup.date().required('Please select the due date!'),
            priority: Yup.string().required('Please select the priority!'),
            // caseID: Yup.string().required('Please enter the case ID!'),
            tokenOrAppointment: Yup.string().required('Please enter the token or appointment details!'),
            description: Yup.string().required('Please enter the task description!'),
            // status: Yup.string().required('Please select the status!'),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
                  Add Task
                </Typography>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Task Name</Typography>
                  <Tooltip title="Enter the name of the task">
                    <TextField
                    placeholder='Enter Task Name'
                      variant="outlined"
                      fullWidth
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Description</Typography>
                  <Tooltip title="Enter the task description">
                    <TextField
                    placeholder='Enter Your Description Here!'
                      variant="outlined"
                      fullWidth
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                      multiline
                      rows={4}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Token/Appointment</Typography>
                  <Tooltip title="Enter the token or appointment details">
                    <TextField
                      
                      variant="outlined"
                      fullWidth
                      name="tokenOrAppointment"
                      value={values.tokenOrAppointment}
                      onChange={handleChange}
                      error={touched.tokenOrAppointment && Boolean(errors.tokenOrAppointment)}
                      helperText={touched.tokenOrAppointment && errors.tokenOrAppointment}
                    />
                  </Tooltip>
                </Grid>
                {/* <Grid item>
                  <Typography variant="h5" fontWeight="bold">Assigned To</Typography>
                  <Tooltip title="Enter the name of the person assigned to the task">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="assigned"
                      value={values.assigned}
                      onChange={handleChange}
                      error={touched.assigned && Boolean(errors.assigned)}
                      helperText={touched.assigned && errors.assigned}
                    />
                  </Tooltip>
                </Grid> */}
             <Grid item>
  <Typography variant="h5" fontWeight="bold">Assigned To</Typography>
  <Tooltip >
    <FormControl variant="outlined" fullWidth>
      {/* <InputLabel id="assigned-multiple-chip-label">Assigned To</InputLabel> */}
      <Select
        labelId="assigned-multiple-chip-label"
        id="assigned-multiple-chip"
        multiple
        value={values.assigned} 
        onChange={(e) => setFieldValue('assigned', e.target.value)} 
        input={<OutlinedInput id="select-multiple-chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={() => {
                  const newAssigned = values.assigned.filter((item) => item !== value);
                  if (JSON.stringify(newAssigned) !== JSON.stringify(values.assigned)) {
                    setFieldValue('assigned', newAssigned);
                  }
                }}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {staffs.map((staff) => (
          <MenuItem
            key={staff.id}
            value={staff.email}
            style={getStyles(staff.email, values.assigned, theme)}
          >
            {staff.email}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Tooltip>
</Grid>


                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Due Date</Typography>
                  <Tooltip title="Select the due date of the task">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="dueDate"
                      type="date"
                      value={values.dueDate}
                      onChange={handleChange}
                      error={touched.dueDate && Boolean(errors.dueDate)}
                      helperText={touched.dueDate && errors.dueDate}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: new Date().toISOString().split('T')[0],  
                      }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Priority</Typography>
                  <Tooltip title="Select the priority of the task">
                    <Select
                      variant="outlined"
                      fullWidth
                      name="priority"
                      value={values.priority}
                      onChange={handleChange}
                      error={touched.priority && Boolean(errors.priority)}
                      helperText={touched.priority && errors.priority}
                    >
                      <MenuItem value="high">High</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="low">Low</MenuItem>
                    </Select>
                  </Tooltip>
                </Grid>
                <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '30px' }}>
                  <Tooltip title="Add the task" arrow>
                    <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                      {isLoading ? 'Saving...' : 'Save Task'}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </FormBox>
  );
}

export default Task;
