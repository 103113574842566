import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import AppGrid from 'src/components/App/AppGrid';
import { Button } from 'src/components/shared';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PickerWithButtonField from 'src/components/App/DatePicker';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddTaskIcon from '@mui/icons-material/AddTask';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchTokenAppointmentsRequest,
  deleteTokenAppointmentRequest,
} from 'src/modules/app/store/appActions';
import storage from 'src/utils/storageUtils';
import moment from 'moment';
import dayjs from 'dayjs';
import toast from 'src/utils/toast';
import PatientList from '../components/patient/PatientList';

function AppointmentContainer() {
  const [selectedCustomDate, setSelectedCustomDate] = useState(null);
  const [open, setOpen] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [tokenAppointments, setTokenAppointments] = useState([]);
  const [selectedTokenAppointmentId, setSelectedTokenAppointmentId] = useState(null);
  const clinic = useSelector((state) => state.app.clinics);
  const [selectedDateFilter, setSelectedDateFilter] = useState('today');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const token = storage.get('TOKEN');
    dispatch(
      fetchTokenAppointmentsRequest({
        token,
        resolve: (data) => setTokenAppointments(data),
        reject: (error) => console.error('Failed to fetch token appointments:', error),
      }),
    );
  }, [dispatch]);
  const handleActionMenu = (event, id) => {
    console.log('Opening action menu for tokenAppointment ID:', id);
    setOpen(event.currentTarget);
    setSelectedTokenAppointmentId(id); 
  };
  const handleDeleteTokenAppointment = async () => {
    if (!selectedTokenAppointmentId) {
      console.error('No Token Appointment ID selected for deletion');
      return;
    }
    const response = await new Promise((resolve, reject) =>
      dispatch(deleteTokenAppointmentRequest(selectedTokenAppointmentId, resolve, reject)),
    );
    if (response) {
      setTokenAppointments(
        tokenAppointments.filter(
          (tokenAppointment) => tokenAppointment.id !== selectedTokenAppointmentId,
        ),
      );
      handleMenuClose();
      toast.success('Appointment deleted successfully');
    }
  };
  const handleMenuClose = () => {
    setOpen(null);
  };
  const handleViewDetails = (appointment) => {
    setSelectedAppointment(appointment);
    setDrawerOpen(true);
    handleMenuClose();
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedAppointment(null);
  };
  const handleEditAppointment = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/patientappointment/${selectedPatientId}`);
  };

  const handleNewAppointmentsClick = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/patientappointment`);
  };




  const filterAppointmentsByDate = () => {
    const today = moment().startOf('day');
    const tomorrow = moment().add(1, 'day').startOf('day');
    const overmorrow = moment().add(2, 'days').startOf('day');

    return tokenAppointments.filter((appointment) => {
      const appointmentDate = moment(appointment.date).startOf('day');

      const isToday = selectedDateFilter === 'today' && appointmentDate.isSame(today, 'day');
      const isTomorrow = selectedDateFilter === 'tomorrow' && appointmentDate.isSame(tomorrow, 'day');
      const isOvermorrow = selectedDateFilter === 'overmorrow' && appointmentDate.isSame(overmorrow, 'day');
      const isCustomDate = selectedDateFilter === 'custom' && selectedCustomDate &&
        appointmentDate.isSame(moment(selectedCustomDate).startOf('day'), 'day');

      return isToday || isTomorrow || isOvermorrow || isCustomDate;
    });
  };


  const handleTodayClick = () => {
    setSelectedDateFilter('today');
    setSelectedCustomDate(null);
  };

  const handleTomorrowClick = () => {
    setSelectedDateFilter('tomorrow');
    setSelectedCustomDate(null);
  };

  const handleOvermorrowClick = () => {
    setSelectedDateFilter('overmorrow');
    setSelectedCustomDate(null);
  };
  const handleCustomDateSelect = (newDate) => {
    setSelectedCustomDate(moment(newDate).startOf('day').toDate());
    setSelectedDateFilter('custom');
  };
  const columns = [
    {
      label: 'Sr.No',
      name: 'index',
      width: '30%',
      options: {
        customBodyRenderLite: (dataIndex) => dataIndex + 1,
      },
    },
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Age',
      name: 'age',
    },
    {
      label: 'Mobile Number',
      name: 'mobileNo',
    },
    {
      label: 'Date',
      name: 'date',
      options: {
        customBodyRender: (date) => moment(date).format('DD MMM YYYY'),
      },
    },
    {
      label: 'Time',
      name: 'time',
      options: {
        customBodyRender: (time) => {
         
          const parsedTime = dayjs(time, ["HH:mm", "YYYY-MM-DDTHH:mm:ss.SSSZ"], true);
          
          return parsedTime.isValid() ? parsedTime.format('hh:mm A') : 'Invalid Time';
        },
      },
    },    
    {
      label: 'Symptoms',
      name: 'symptoms',
    },
    {
      label: '',
      name: 'Action',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const appointment = tokenAppointments[dataIndex];
          return (
            <>
              <IconButton
                onClick={(event) => handleActionMenu(event, tokenAppointments[dataIndex]?.id)}
              >
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: { width: 170 },
                }}
              >
                <MenuItem>
                  <Stack spacing={1} direction="row">
                    <EditIcon />
                    <span>Change Status</span>
                  </Stack>
                </MenuItem>
                <MenuItem>
                  <Stack spacing={1} direction="row">
                    <CheckCircleIcon />
                    <span>Attend Now</span>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={() => handleViewDetails(appointment)}>
                  <Stack spacing={1} direction="row">
                    <VisibilityIcon />
                    <span>View Details</span>
                  </Stack>
                </MenuItem>
                <MenuItem>
                  <Stack spacing={1} direction="row">
                    <AddTaskIcon />
                    <span>Add Task</span>
                  </Stack>
                </MenuItem>
                <MenuItem>
                  <Stack spacing={1} direction="row">
                    <EventOutlinedIcon />
                    <span>Remind</span>
                  </Stack>
                </MenuItem>
                <MenuItem>
                  <Stack spacing={1} direction="row">
                    <BookmarkIcon />
                    <span>Follow Up</span>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleEditAppointment}>
                  <Stack spacing={1} direction="row">
                    <EditIcon />
                    <span>Edit</span>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleDeleteTokenAppointment} sx={{ color: 'error.main' }}>
                  <Stack spacing={1} direction="row">
                    <DeleteIcon />
                    <span>Delete</span>
                  </Stack>
                </MenuItem>
              </Popover>
            </>
          );
        },
      },
    },
  ];
  return (
    <>
      <Box
        sx={{
          marginBottom: 2,
          position: 'relative',
          bottom: '40px',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={10} container>
            <Grid item xs={6}>
              <Stack spacing={1} direction="row">
                <Button variant="contained" onClick={handleTodayClick}>Today</Button>
                <Button variant="contained" onClick={handleTomorrowClick}>Tomorrow</Button>
                <Button variant="contained" onClick={handleOvermorrowClick}>Day After</Button>
                <PickerWithButtonField onDateSelect={handleCustomDateSelect} />
              </Stack>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={handleNewAppointmentsClick} c>
                New Appointments
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          marginBottom: 2,
          position: 'relative',
          bottom: '40px',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <AppGrid
              columns={columns}
              data={filterAppointmentsByDate()}
              selectableRows="none"
              onFilterChange={() => { }}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => { }}
              onSort={() => { }}
              onClearFilters={() => { }}
              title=""
              titleProps={{}}
              pagingType="table"
            />
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
        <Box sx={{ width: 300, padding: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            Appointment Details
          </Typography>
          {selectedAppointment && (
            <>
              <Typography variant="body1">
                <strong>Name:</strong> {selectedAppointment.patient.fullName}
              </Typography>
              <Typography variant="body1">
                <strong>Age:</strong> {selectedAppointment.patient.age}
              </Typography>
              <Typography variant="body1">
                <strong>Mobile Number:</strong> {selectedAppointment.patient.contactNo}
              </Typography>
              <Typography variant="body1">
                <strong>Date/Time:</strong> {selectedAppointment.date} {selectedAppointment.time}
              </Typography>
              <Typography variant="body1">
                <strong>Symptoms:</strong> {selectedAppointment.addnotes}
              </Typography>
            </>
          )}
          <Button variant="contained" onClick={closeDrawer} sx={{ marginTop: 2 }}>
            Close
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
export default PatientList(AppointmentContainer);
