import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Typography, Button, Tooltip, TextField, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import { postPatientInfoRequest } from '../../store/appActions';
import { FormBox } from './style';
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import InputComponent from 'src/components/shared/Form/Input';


function PatientInfo() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { patient } = location.state || {};

    const validationSchema = (Yup.object().shape({
        weight: Yup.number()
            .typeError('Weight must be a number')
            .required("Weight is require")
            .positive('Weight must be a positive number'),

        height: Yup.number()
            .typeError('Height must be a number')
            .required('Height is required')
            .positive('Height must be a positive number'),

    }))


    const handleSubmit = async (values, { resetForm }) => {
        try {
            const token = storage.get('TOKEN');
            if (!token) throw new Error('Token not found.');
            const decodedToken = jwtDecode(token);
            const user = decodedToken?.user;
            if (!user) throw new Error('User data not available.');
            if (!patient?.id) throw new Error('Patient data or ID is not available.');

            const patientInfos = {

                patient: patient.id,
                user,
                weight: values.weight,
                height: values.height,
            };
            console.log("patient Info data", patientInfos)
            const promise = await new Promise((resolve, reject) => {
                dispatch(postPatientInfoRequest({ data: patientInfos, resolve, reject }))
            })
            const response = await promise;
            console.log('Before toast:', response);
            toast.success(response.message || 'PatientInfo Added successfully');
            resetForm();
        } catch (error) {
            console.error('Error submitting treatment:', error);
        }
    };

    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                initialValues={{
                    weight: '',
                    height: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, handleBlur, touched, errors, setFieldValue }) => (
                    <Form noValidate>
                        <Box sx={{ marginTop: '10px', padding: '20px' }}>
                            <Grid container direction="column" spacing={2} sx={{ padding: '20px' }}>
                                <Grid item>
                                    <Typography variant="h4" color="#393A96" fontWeight="bold">
                                        Patient Information
                                    </Typography>

                                </Grid>
                                <Grid item container
                                    direction="column"
                                // spacing={2}
                                >
                                    <Typography variant="h5" fontWeight="bold" mb={0.7} >
                                        Weight
                                    </Typography>

                                    <InputComponent
                                        variant="outlined"
                                        name="weight"
                                        fullwidth={true}
                                        value={values.weight}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.weight && Boolean(errors.weight)}
                                        helperText={touched.weight && errors.weight}
                                    />
                                </Grid>


                                <Grid item container
                                    direction="column">
                                    <Typography variant="h5" fontWeight="bold" mb={0.7}>
                                        Height
                                    </Typography>
                                    <InputComponent
                                        variant="outlined"
                                        name="height"
                                        fullwidth={true}
                                        value={values.height}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.height && Boolean(errors.height)}
                                        helperText={touched.height && errors.height}
                                    />
                                </Grid>
                                <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                                    <Tooltip title="Save Patient Info" arrow>
                                        <Button variant="contained" type='submit'>
                                            Save Report
                                        </Button>
                                    </Tooltip>
                                </Grid>

                            </Grid>
                        </Box>
                    </Form>
                )}
            </Formik>

        </FormBox>

    )
}
export default PatientInfo
