import React, { useState, useEffect } from 'react';
import { FormBox } from './style';
import { Grid, Typography, Button, Tooltip, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import InputAutocomplete from 'src/components/shared/Form/AutoComplete';
import Autocomplete from '@mui/material/Autocomplete';
// import tokenImage from 'src/assets/token.png'; // Import the image file
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';
import toast from 'src/utils/toast';
import { postTokenRequest } from 'src/modules/app/store/appActions';


function PatientToken() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { patientData, patient } = location.state || {};
    console.log("my patient tokens", patient)
    console.log("my patient Data", patientData)
    const now = new Date();
    const [selectedSymptom, setSelectedSymptom] = useState(null);
    const clinic = useSelector((state) => state.app.clinics);
    const navigate = useNavigate();
    const handleButtonClick = () => {
        navigate(`/${clinic.clinicId}/${clinic.doctorId}/Patient`);
    };

    const symptoms = [
        { label: 'Fever', value: 'fever' },
        { label: 'Neck Pain', value: 'neckPain' },
        { label: 'Headache', value: 'headache' },
        { label: 'Cough', value: 'cough' }
    ];

    useEffect(() => {
        if (!patientData) {
            toast.error('No patient selected. Please select a patient.');
        }
    }, [patientData]);

    const handleTokenBook = async () => {
        try {
            const token = storage.get('TOKEN');
            const decodedToken = jwtDecode(token);
            const user = decodedToken?.user;


            if (!user) {
                throw new Error('User information is missing. Please log in again.');
            }

            if (!clinic || !clinic.clinicId) {
                throw new Error('Clinic information is missing.');
            }

            if (!selectedSymptom) {
                throw new Error('Selected symptom is missing.');
            }
            console.log('Selected Symptom:', selectedSymptom);

            const tokenappointment = {
                addnotes: selectedSymptom.value,
                clinic: clinic.clinicId,
                user,
                patient: patientData,
                scheduleType: 'token',
                date: now.toISOString().split('T')[0],
                time: now.toTimeString().split(' ')[0],
            };

            const promise = await new Promise((resolve, reject) => {
                dispatch(postTokenRequest({ data: tokenappointment, resolve, reject }))
            })
            const response = await promise;
            toast.success(response.message || 'Token created successfully');
            navigate(`/${clinic.clinicId}/${clinic.doctorId}/notes/${patientData}`, {
                state: { patient: patientData }
            });            // resetForm();
        } catch (error) {
            console.error('Error in handleSaveToken:', error);
            toast.error('Failed to add appointment: ' + (error.message || error));
        }
    };

    return (
        <FormBox height="45vh">
            <Grid container justifyContent="center" sx={{ marginBottom: '10px' }}>
                {/* <img src={tokenImage} alt="Token" style={{ width: '100px', height: '100px' }} /> */}
            </Grid>
            <Typography
                variant="h4"
                color="#393A96"
                fontWeight="bold"
                sx={{ position: 'relative', top: 20, left: '4%' }}
            >
                Token
            </Typography>
            <Grid
                container
                spacing={2}
                sx={{ justifyContent: 'flex-end', position: 'relative', right: '20px' }}
            >
                <Grid item>
                    <Tooltip title="Add a new patient">
                        <Button variant="contained" sx={{ textAlign: 'center' }} onClick={handleButtonClick}>
                            Add New Patient
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid item>
                    {/* <Typography variant="h5" fontWeight="bold">
            Symptoms
          </Typography> */}
                    <Tooltip title="Select patient symptoms">
                        <Autocomplete
                            variant="outlined"
                            sx={{ position: 'relative', bottom: '10px' }}
                            fullWidth
                            options={symptoms}
                            getOptionLabel={(option) => option.label}
                            value={selectedSymptom}
                            // onChange={handleSymptomChange}
                            onChange={(event, newValue) => setSelectedSymptom(newValue)}
                            renderInput={(params) => (
                                <TextField {...params} label="Symptoms" variant="outlined" />
                            )}
                        />
                    </Tooltip>
                </Grid>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        <Tooltip title="Book the patient token">
                            <Button variant="contained" sx={{ textAlign: 'center' }} onClick={handleTokenBook}>
                                Save Token
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </FormBox>
    );
}

export default PatientToken;
