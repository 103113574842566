import React from "react";
import Welcome from "../../components/patient/Welcome";
import Task from "../../components/RightSidebar/Task";
import Information from "../../components/patient/Information";


import { Container } from "@mui/material";
function TaskContainer() {
    return (
        <>
            {/* <Welcome /> */}
            <Information />

            <Container maxWidth='lg'>
                <Task />
            </Container>
        </>
    )
}
export default TaskContainer