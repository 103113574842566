import React, { useState, useEffect, useMemo } from 'react';
import { FormBox } from './style';
import { Grid, Typography, Tooltip, Box, Checkbox } from '@mui/material';
import { Button } from 'src/components/shared';
import InputComponent from 'src/components/shared/Form/Input';
import Select from 'src/components/shared/Form/Select';
import Textarea from 'src/components/shared/Form/Textarea';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchTemplatesTitleRequest,
  postMedicalPrescriptionRequest,
  fetchPrescriptionRequest,
  postTemplateRequest
} from '../../store/appActions';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';
import toast from 'src/utils/toast';
import { Formik, Form } from 'formik';

function Prescription() {
  const [template, setTemplate] = useState('');
  const [notes, setNotes] = useState('');
  const [saveAs, setSaveAs] = useState('');
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [prescriptionText, setPrescriptionText] = useState('');
  const [latestPrescriptions, setLatestPrescriptions] = useState([]);
  const clinic = useSelector((state) => state.app.clinics.currentClinic);
  const templates = useSelector((state) => state.app.templates);
  const medicalRecords = useSelector((state) => state.app.medicalRecords);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { patient } = location.state || {};


  useEffect(() => {
    if (patient?.id) {
      dispatch(fetchPrescriptionRequest(patient.id));
    }
  }, [dispatch, patient]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setIsLoading(true);
        await new Promise((resolve, reject) => {
          dispatch(fetchTemplatesTitleRequest(resolve, reject));
        });
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, [dispatch]);

  useEffect(() => {
    if (location.state?.prescription) {
      const { prescription } = location.state;
      setPrescriptionText(prescription.text || '');
      setNotes(prescription.text || '');
    }
  }, [location.state]);

  useEffect(() => {
    if (Array.isArray(medicalRecords) && medicalRecords.length > 0) {
      const latestRecords = medicalRecords.slice(0, 5);
      setLatestPrescriptions(latestRecords);
    } else {
      console.error('Medical records is not an array or is undefined');
      setLatestPrescriptions([]);
    }
  }, [medicalRecords]);

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const templateOptions = useMemo(() => {
    if (!templates || !Array.isArray(templates)) {
      return [];
    }
    return templates.map((template) => ({
      label: template.title,
      value: template.id,
    }));
  }, [templates]);

  const handleTemplateChange = (event) => {
    const selectedTemplateId = event.target.value;
    setTemplate(selectedTemplateId);

    const selected = templates.find((template) => template.id === selectedTemplateId);
    if (selected) {
      setSelectedTemplate(selected);
      setPrescriptionText(selected.prescription || '');
      setNotes(selected.prescription || '');
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {

      const token = storage.get('TOKEN');
      const decodedToken = jwtDecode(token);
      const user = decodedToken?.user;
      if (!user) throw new Error('User data not available.');
      if (!patient?.id) throw new Error('Patient data or ID is not available.');
      if (!prescriptionText) throw new Error('Prescription text is required.');
      const medicalRecord = {
        notes: prescriptionText,
        patient: patient.id,
        tokenappointment: patient.tokenappointment,
        user,
        recordType: 'prescription',
        patientView: values.patientView ? 'Public' : 'Private',
        date: new Date().toISOString(),
        time: new Date().toTimeString().split(' ')[0],
      };
      const prescriptionResponse = await new Promise((resolve, reject) => {
        dispatch(postMedicalPrescriptionRequest({ data: medicalRecord, resolve, reject }));
      });
      toast.success(prescriptionResponse.message || 'Prescription created successfully');
      if (checked) {
        const templateData = {
          clinic: clinic.id,
          text: prescriptionText,
          type: "prescription",
          title: values.saveAs,
        };
        try {
          const templateResponse = await new Promise((resolve, reject) => {
            dispatch(postTemplateRequest({ data: templateData, resolve, reject }));
          });
          toast.success(templateResponse.message);
        } catch (templateError) {
          toast.error('Failed to save template: ' + (templateError.message || templateError));
        }
      }
      resetForm();
      dispatch(fetchPrescriptionRequest(patient.id));

      // Optional: Navigate to SharePrescription page (if necessary)
      // const path = `/${clinic.clinicId}/${clinic.doctorId}/SharePrescripation/${patient.id}`;
      // navigate(path, {
      //   state: {
      //     title: values.saveAs || 'prescription',
      //     prescription: prescriptionText,
      //   },
      // });
    } catch (error) {
      toast.error('Failed to add prescription: ' + (error.message || error));
    }
  };

  const token = storage.get('TOKEN');
  const decodedToken = jwtDecode(token);
  const user = decodedToken?.user;
  const handleRecordClick = (record) => {
    const patientData = { id: patient.id, name: patient.name, symptoms: patient.symptoms }; // Ensure patient is available

    navigate(`/${clinic.id}/${user}/SharePrescripation/${patient.id}`, {
      state: { record, patientData },
    });
  };



  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    console.log("Checkbox checked:", isChecked);
  }
  if (isLoading) {
    return <Typography>Loading templates...</Typography>;
  }
  if (templateOptions.length === 0) {
    return <Typography>No templates available.</Typography>;
  }
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormBox sx={{ marginTop: '20px', padding: '30px' }}>
          <Formik
            initialValues={{ notes: prescriptionText, template: '', patientView: false, saveAs: '' }}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <Grid container direction="column" spacing={2}>
                  <Typography
                    variant="h4"
                    color="#393A96"
                    fontWeight="bold"
                    sx={{ marginLeft: '2%' }}
                  >
                    Prescription
                  </Typography>

                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Prescription Template
                    </Typography>
                    <Select
                      fullWidth
                      variant="outlined"
                      name="template"
                      value={values.template}
                      onChange={(e) => {
                        handleChange(e);
                        handleTemplateChange(e);
                      }}
                      onBlur={handleBlur}
                      placeholder="Select a template"
                      options={templateOptions}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Prescription
                    </Typography>
                    <Tooltip title="Enter the prescription details here" arrow>
                      <Textarea
                        variant="outlined"
                        fullWidth
                        rows={4}
                        minRows={4}
                        maxRows={4}
                        name="notes"
                        value={prescriptionText}
                        onChange={(e) => {
                          handleChange(e);
                          setPrescriptionText(e.target.value);
                        }}
                        onBlur={handleBlur}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item container alignItems="center">
                    <Tooltip title="Check to save the prescription with a specific name" arrow>
                      <Checkbox checked={checked} onChange={handleCheckboxChange} />
                    </Tooltip>
                    <Typography>Save As:</Typography>
                    {checked && (
                      <Tooltip title="Enter a name to save the prescription as" arrow>
                        <InputComponent
                          variant="outlined"
                          name="saveAs"
                          value={values.saveAs}
                          onChange={handleChange}
                          sx={{ width: '100%', marginLeft: '10px' }}
                        />
                      </Tooltip>
                    )}
                  </Grid>

                  <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                    <Tooltip title="Save and Share Prescription" arrow>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={checked && !values.saveAs}
                      >
                        Save and Share Prescription
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </FormBox>
      </Grid>

      {/* History Section */}
      <Grid item>
        <FormBox >
          <Grid container direction="column" spacing={2} sx={{ padding: '20px' }}>
            <Grid item>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                Latest Prescriptions History
              </Typography>
              {latestPrescriptions && latestPrescriptions.length > 0 ? (
                latestPrescriptions.map((record, index) =>
                (
                  <Box
                    key={index}
                    sx={{ marginTop: '10px', padding: '10px', border: '1px solid #ccc', cursor: 'pointer' }}
                    onClick={() => handleRecordClick(record)}
                  >
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      {`Date: ${new Date(record.date).toLocaleDateString() || 'N/A'}`}
                    </Typography>
                    <Typography variant="h5" >
                      {record.prescription || 'N/A'}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body1">No prescriptions available.</Typography>
              )}
            </Grid>
          </Grid>
        </FormBox>
      </Grid>
    </Grid>
  );
}

export default Prescription;
